.App {
	text-align: center;
	display: flex;
	justify-content: center;
}

.wrapper {
	width: 60em;
  padding: 1em;
}
